import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

import tickX from 'data/tickx'

class Glasgow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
            tickX: []
        }
    }

    componentWillMount() {
        this.loadTickX();
    }

    componentWillUnmount() {
        this.setState({
            tickX: []
        });
    };

    loadTickX = () => {
        this.setState({
            tickX: tickX[2]
        });
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'glasgow') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        return (
            <Calendar
                events={futureEvents}
                defaultDate={moment('2022-01-06', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2022-01-06', 'YYYY-MM-DD'),
                    end: moment('2022-01-23', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2022-01-06', 'YYYY-MM-DD'),
                    end: moment('2022-01-23', 'YYYY-MM-DD')
                }}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const { phase, siteId } = this.props;
        const { tickX } = this.state;
        // const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                    <h1 className="text-center">THE MORMONS ARE COMING TO GLASGOW</h1>
                                }
                                {phase === 'open' &&
                                    <React.Fragment>
                                        <h1>How to buy your tickets for GLASGOW</h1>
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                    <>
                        <div className="section-intro section-sign-up">
                            <Container>
                                <Row>
                                    <Col xs={12}>

                                        <div className="section-intro__wrapper">
                                            <div className="section-intro__wrapper-header">
                                                <h2>TICKET TIPS</h2>
                                            </div>
                                            <div className="section-intro__wrapper-body">
                                                <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                    FEW HANDY TIPS:</h3>

                                                <ul>
                                                    <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                        priority seat
                                                        releases
                                                    </li>
                                                    <li>For a wider range of prices and seats, try midweek performances</li>
                                                    {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="section-accordion" id="section-accordion">
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <div className="section-accordion__wrap">
                                            <button
                                                className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseA === true ? 'active' : ''}`}
                                                onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                                ONLINE BOOKINGS
                                            </button>
                                            <Collapse
                                                isOpen={collapses.collapseA}
                                                onEntering={this.onEntering}
                                                onEntered={this.onEntered}
                                                onExiting={this.onExiting}
                                                onExited={this.onExited}
                                                className={`section-accordion__wrap-content accordion-content tickX a ${collapses.collapseA === true ? 'active' : ''} ${siteId}`}>

                                                <div className="table">
                                                    <a className="table-row" href={`/glasgow/tickets/?configId=${tickX['glasgow'][0]['configId']}`}>
                                                        <div className="table-cell">1</div>
                                                        <div className="table-cell">
                                                            <div dangerouslySetInnerHTML={{ __html: tickX['glasgow'][0]['copy'] }} />
                                                        </div>
                                                        <div className="table-cell">
                                                            <div className={`btn-buy GTM-BOMLiverpooolPerformanceDate`} id="versaID-glasgow-PerformanceDate" />
                                                        </div>
                                                    </a>
                                                    <a className="table-row" href={`/glasgow/tickets/?configId=${tickX['glasgow'][1]['configId']}`}>
                                                        <div className="table-cell">2</div>
                                                        <div className="table-cell">
                                                            <div dangerouslySetInnerHTML={{ __html: tickX['glasgow'][1]['copy'] }} />
                                                        </div>
                                                        <div className="table-cell">
                                                            <div className={`btn-buy GTM-BOMLiverpooolUnder30`} id="versaID-glasgow-Under30" />
                                                        </div>
                                                    </a>
                                                    <a className="table-row" href={`/glasgow/tickets/?configId=${tickX['glasgow'][2]['configId']}`}>
                                                        <div className="table-cell">3</div>
                                                        <div className="table-cell">
                                                            <div dangerouslySetInnerHTML={{ __html: tickX['glasgow'][2]['copy'] }} />
                                                        </div>
                                                        <div className="table-cell">
                                                            <div className={`btn-buy GTM-BOMLiverpooolBestSeats`} id="versaID-glasgow-BestSeats" />
                                                        </div>
                                                    </a>
                                                </div>

                                            </Collapse>

                                            <button
                                                className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseD === true ? 'active' : ''}`}
                                                onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                                PHONE BOOKINGS
                                            </button>
                                            <Collapse isOpen={collapses.collapseD}
                                                      className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                                <p><strong>BOOK BY PHONE</strong></p>

                                                <p>Telephone Booking* <a href="tel:03330096690">03330 096 690</a></p>
                                                <p className="small-copy">*Calls charged at standard geographic rates and count towards any inclusive minutes in the same way as calls to 01 and 02 numbers.</p>

                                            </Collapse>

                                            <button
                                                className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseC === true ? 'active' : ''}`}
                                                onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                                Group Bookings
                                            </button>
                                            <Collapse isOpen={collapses.collapseC}
                                                      className={`section-accordion__wrap-content accordion-content has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                                <div className="section-accordion__wrap-content-inner">
                                                    <p>Group Bookings** <a href="tel:02072061174">0207 206 1174</a></p>
                                                    <p>**Calls charged at local rate.</p>
                                                </div>
                                            </Collapse>

                                            <button
                                                className={`section-accordion__wrap-btn accordion-btn tickX ${collapses.collapseB === true ? 'active' : ''}`}
                                                onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                                Access Information
                                            </button>
                                            <Collapse isOpen={collapses.collapseB}
                                                      className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                                <div className="section-accordion__wrap-content-inner">
                                                    <p>Access Bookings <a href="tel:08009126971">0800 912 6971</a></p>
                                                    <p>&nbsp;</p>
                                                    <p><strong>Typetalk Number</strong></p>
                                                    <p>&nbsp;</p>
                                                    <p>18001 0800 912 6971</p>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>
                }

                {phase === 'registration' &&
                    <div className="section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-sign-up__wrap">
                                        <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW</h2>
                                        <SignUpForm
                                            className="sign-up-form"
                                            location="/glasgow/"
                                            locationName="glasgow"
                                            phase={phase}
                                            thankYouPage={true}
                                        />
                                        <p className="small-copy">
                                            By submitting your email address, you are agreeing to receive marketing
                                            emails about The Book of Mormon. You can opt out of receiving
                                            these emails at any time.
                                            <br/>To see full details of our privacy policy <Link
                                            to={`/glasgow/privacy-policy/`}>click here</Link>.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
                {phase === 'presale' &&
                    <div className="section-sign-up space-below">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-sign-up__wrap orange-border">
                                        <h3 className="mb-2">THE MORMONS ARE COMING TO GLASGOW</h3>
                                        <div className="narrow">
                                            <p>&nbsp;</p>
                                            <p>Performances will take place at</p>
                                            <p>&nbsp;</p>
                                            <p className="big orange text-uppercase"><strong>King’s Theatre, Glasgow</strong></p>
                                            <p>&nbsp;</p>
                                            <p className="big orange text-uppercase"><strong>6 – 23 January 2022</strong></p>
                                            <p>&nbsp;</p>
                                            <p>Sign up here to be the first to hear when tickets are released!</p>
                                        </div>
                                        <SignUpForm
                                            className="sign-up-form"
                                            location="/glasgow/"
                                            locationName="glasgow"
                                            phase={phase}
                                            thankYouPage={true}
                                        />
                                        <p className="small-copy">
                                            By submitting your email address, you are agreeing to receive marketing
                                            emails about The Book of Mormon. You can opt out of receiving
                                            these emails at any time.
                                            <br/>To see full details of our privacy policy <Link
                                            to={`/glasgow/privacy-policy/`}>click here</Link>.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
                {phase === 'open' &&
                    <div className="section-show-info">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-show-info__wrap">
                                        <div className="row">
                                            <div className="section-show-info__wrap-map">
                                                <h2 className="d-lg-none">Show Info</h2>
                                                <div className={`map glasgow`}>
                                                </div>
                                            </div>

                                            <div className="section-show-info__wrap-content">
                                                <div className="content">
                                                    <h2 className="d-none d-lg-block">Show Info</h2>
                                                    <h3>From 9 November 2022</h3>
                                                    <p>Theatre Royal Glasgow<br/>
                                                        282 Hope St, Glasgow G2 3QA
                                                    </p>

                                                    <a className="btn btn-google-maps"
                                                       href="https://goo.gl/maps/suVVMZiVTvVdk3jR8"
                                                       target="_blank"
                                                       rel="noreferrer noopener">
                                                        <span className="sr-only">Theatre Royal Glasgow Map</span>
                                                    </a>

                                                    <h3>PERFORMANCE TIMES</h3>
                                                    <p>Mon – Sat 7.30pm</p>
                                                    <p>Fri & Sat 2.30pm</p>

                                                    <h3 className="mt-2">Running Time</h3>
                                                    <p>2 hours 20 minutes, including interval</p>
                                                    <p className="med">Parental Advisory:</p>
                                                    <p className="med">Age Guidance: 14+ (No Under 2s). Contains strong language, adult themes, strobe lighting and bangs.</p>
                                                    <p className="med">Under 16s must be accompanied by an adult.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
                {/*<div className={`coronaMsg ${!this.state.coronaMessageOpen && 'd-none'}`}>*/}

                {/*    <div className="coronaMsg--content">*/}

                {/*        <p>Under the direction of the UK Government, theatre shows playing to more than **** people will suspend all performances immediately in support of the health and well-being of the theatregoing public, as well as those who work in the theatre industry. Performances will commence the week of ** ***** ****</p>*/}

                {/*        <p>Those holding tickets for performances until ** ***** **** should contact their point of purchase for refunds and exchanges.</p>*/}

                {/*        <span className="coronaMsg--closeBtn" role="button" tabIndex={0} onKeyPress={this.closeCoronaMessage} onClick={this.closeCoronaMessage}>X</span>*/}

                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Glasgow events={data}
                     multiLang={props.multiLang}
                     phase={props.phase}
                     siteId={props.siteId}
                     language={props.language}
            />
        )}
    />
)