import React, { useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import { Container } from "reactstrap";
import NewSignUpForm from "../NewSignUpForm";
import styled from "styled-components";
import signUpHeader from "images/headers/header-signup.png";
import signUpHeaderMobile from "images/headers/header-signup-mobile.jpg";

const StyledTitle = styled.div`
  width: 100%;
  text-align: center;
  span {
    display: block;
    color: #000000;
    font-size: 16px;
    padding: 5px 0;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }
  h1 {
    @media (min-width: 768px) {
      font-size: 35px;
    }
    @media (min-width: 1280px) {
      font-size: 55px;
    }
  }
  h2 {
    color: #de691c;
    font-weight: 900;
    font-family: proxima-nova, sans-serif;
    margin-bottom: 0.35rem;
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }
  h3 {
    color: black;
    font-weight: 500;
    /* font-family: proxima-nova, sans-serif; */
    margin-top: 1rem;
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
    /* @media (min-width: 1280px){
            font-size: 20px;
        } */
  }
  h4 {
    font-size: 18px;
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }
`;

const Content = styled.div`
  padding: 3rem 0;
  width: 100%;

  h3 {
    text-transform: none;
  }
`;

const SignUpPage = ({ props }) => {
  const [city, setCity] = useState(null);
  // protect output onto website
  const cities = [
    "birmingham",
    "manchester",
    "bristol",
    "bradford",
    "edinburgh",
    "cardiff",
    "london",
    "dublin",
    "leeds",
    "liverpool",
    "newcastle",
    "norwich",
    "nottingham",
    "southampton",
    "sunderland",
    "glasgow",
    "zurich",
    "hull",
    "plymouth"
  ];

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const city = urlParams.get("city");
    console.log(city)
    if (cities.includes(city)) {
      setCity(city);
    }
  }, []);

  const data = {
    title: `The Book Of Mormon Musical | Official Website | Signup`,
  };

  return (
    <Layout data={data}>
      <div className="performance__header">
        <div className="images">
          <div className="mobile">
            <img src={signUpHeaderMobile} alt="" />
          </div>
          <div className="desktop">
            <img src={signUpHeader} alt="" />
          </div>
        </div>
      </div>
      <Container fluid={true}>
        <Container className="">
          <Content>
            {city ? (
              <>
                {/* {city !== 'manchester' || 'birmingham' || 'edinburgh' && (
                                <h1 className="mb-4">THE MORMONS ARE COMING BACK TO {city.toUpperCase()}</h1>
                            )} */}
                {city === "manchester" && (
                  <StyledTitle>
                    <h1 className="mb-2">
                      <span>COMING TO</span>
                      PALACE THEATRE MANCHESTER
                      <span>FROM</span>
                      11 SEPTEMBER - 5 OCTOBER 2024
                    </h1>
                    <h2>GENERAL ON SALE FROM FRIDAY 10TH NOVEMBER</h2>
                    <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4>
                  </StyledTitle>
                  // <h1 className='mb-3'>Coming to the Manchester Palace from 11 September – 5 October 2024 <br /> General On sale from Friday 10th November</h1>
                )}
                {city === "birmingham" && (
                  <StyledTitle>
                    <h1 className="mb-2">
                      <span>COMING TO</span>
                      THE ALEXANDRA, BIRMINGHAM
                      <span>FROM</span>3 - 28 December 2024
                    </h1>
                    <h2>GENERAL ON SALE FROM FRIDAY 10TH NOVEMBER</h2>
                    <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4>
                  </StyledTitle>
                )}
                {city === "edinburgh" && (
                  <StyledTitle>
                    <h1 className="mb-2">
                      <span>COMING TO</span>
                      Edinburgh Playhouse
                      <span>FROM</span>
                      15 October - 2 November 2024
                    </h1>
                    <h2>GENERAL ON SALE FROM FRIDAY 10TH NOVEMBER</h2>
                    <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4>
                  </StyledTitle>
                )}
                {city === "bradford" && (
                  <StyledTitle>
                    <h1 className="mb-2">THE MORMANS ARE COMING TO BRADFORD</h1>
                    {/* <h1 className="mb-2">
                                  <span>COMING TO</span>
                                  Edinburgh Playhouse
                                  <span>FROM</span>
                                  15 October - 2 November 2024
                              </h1> */}
                    <h2 style={{ color: "black" }}>
                      COMING TO ALHAMBRA THEATRE, BRADFORD FROM 5- 23 NOVEMBER
                      2024
                    </h2>
                    <h3>GENERAL ON SALE FROM MONDAY 15 JANUARY</h3>
                    <h3>SIGN UP FOR OUR EMAIL LIST</h3>
                    {/* <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4> */}
                  </StyledTitle>
                )}
                {city === "hull" && (
                  <StyledTitle>
                    <h1 className="mb-2">
                      THE MORMONS ARE COMING TO <br /> Hull
                    </h1>
                    {/* <h1 className="mb-2">
                                 <span>COMING TO</span>
                                 Edinburgh Playhouse
                                 <span>FROM</span>
                                 15 October - 2 November 2024
                             </h1> */}
                    <h2 style={{ color: "black" }}>
                    COMING TO HULL NEW THEATRE FROM 4 - 22 MARCH 2025

                     <br />
                     GENERAL ON SALE FROM THURSDAY 7 MARCH  

                    </h2>
                    {/* <h2 style={{color: 'black'}}>Priority booking opens Monday 26 February.</h2>  */}
                    {/* <h2 style={{color: 'black'}}>COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 – 25 JANUARY 2025</h2> */}
                    {/* <h3>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h3> */}
                    {/* <h3>SIGN UP FOR OUR EMAIL LIST</h3> */}
                    {/* <p>Priority booking opens Monday 26 February.</p> */}
                    {/* <p>Yes! I would like to recieve updates, special offers and other information from The Book of Mormon musical. </p> */}
                    {/* <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4> */}
                  </StyledTitle>
                )}
                {city === "dublin" && (
                  <StyledTitle>
                    <h1 className="mb-2">
                    THE MORMONS ARE COMING BACK TO DUBLIN


                    </h1>
                    <h2 style={{ color: "black" }}>
                    COMING TO BORD GÁIS ENERGY THEATRE, DUBLIN FROM 1 APRIL - 3 MAY 2025


                     <br />
                     GENERAL ON SALE FROM FRIDAY 26 JULY

                    </h2>
                    {/* <h1 className="mb-2">
                                 <span>COMING TO</span>
                                 Edinburgh Playhouse
                                 <span>FROM</span>
                                 15 October - 2 November 2024
                             </h1> */}
                    {/* <h2 style={{ color: "black" }}>
                    SIGN UP BELOW UP BELOW TO ACCESS PRIORITY BOOKING


                    </h2> */}
                    <p>SIGN UP FOR OUR EMAIL LIST
                    </p>
                    {/* <h2 style={{color: 'black'}}>Priority booking opens Monday 26 February.</h2>  */}
                    {/* <h2 style={{color: 'black'}}>COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 – 25 JANUARY 2025</h2> */}
                    {/* <h3>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h3> */}
                    {/* <h3>SIGN UP FOR OUR EMAIL LIST</h3> */}
                    {/* <p>Priority booking opens Monday 26 February.</p> */}
                    {/* <p>Yes! I would like to recieve updates, special offers and other information from The Book of Mormon musical. </p> */}
                    {/* <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4> */}
                  </StyledTitle>
                )}
                {city === "plymouth" && (
                  <StyledTitle>
                    <h1 className="mb-2">THE MORMONS ARE COMING TO PLYMOUTH</h1>
                    {/* <h1 className="mb-2">
                                    <span>COMING TO</span>
                                    Edinburgh Playhouse
                                    <span>FROM</span>
                                    15 October - 2 November 2024
                                </h1> */}
                    <h2 style={{ color: "black" }}>
                      COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 - 25 JANUARY
                      2025
                      <br />
                      GENERAL ON SALE FROM MONDAY 1 MARCH
                    </h2>
                    {/* <h2 style={{color: 'black'}}>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h2>                                 */}
                    {/* <h2 style={{color: 'black'}}>Priority booking opens Monday 26 February.</h2>  */}
                    {/* <h2 style={{color: 'black'}}>COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 – 25 JANUARY 2025</h2> */}
                    {/* <h3>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h3> */}
                    {/* <h3>SIGN UP FOR OUR EMAIL LIST</h3> */}
                    <p>SIGN UP FOR OUR EMAIL LIST</p>
                    {/* <p>Yes! I would like to recieve updates, special offers and other information from The Book of Mormon musical. </p> */}
                    {/* <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4> */}
                  </StyledTitle>
                )}
                {city === "zurich" && (
                  <StyledTitle>
                    <h1 className="mb-2">THE MORMONS ARE COMING TO Zurich</h1>
                    {/* <h1 className="mb-2">
                                    <span>COMING TO</span>
                                    Edinburgh Playhouse
                                    <span>FROM</span>
                                    15 October - 2 November 2024
                                </h1> */}
                    <h2 style={{ color: "black" }}>
                    COMING TO THEATRE 11 ZURICH FROM 4 - 23 FEBRUARY 2025
                      <br />
                      GENERAL ON SALE FROM MONDAY 22 APRIL

                    </h2>
                    {/* <h2 style={{color: 'black'}}>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h2>                                 */}
                    {/* <h2 style={{color: 'black'}}>Priority booking opens Monday 26 February.</h2>  */}
                    {/* <h2 style={{color: 'black'}}>COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 – 25 JANUARY 2025</h2> */}
                    {/* <h3>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h3> */}
                    {/* <h3>SIGN UP FOR OUR EMAIL LIST</h3> */}
                    {/* <p>SIGN UP FOR OUR EMAIL LIST</p> */}
                    {/* <p>Yes! I would like to recieve updates, special offers and other information from The Book of Mormon musical. </p> */}
                    {/* <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4> */}
                  </StyledTitle>
                )}
              </>
            ) : (
              <>
                {/* <h1 className="mb-4">THE MORMONS ARE COMING BACK!</h1>
                                <h4 className="mb-5">Sign up below to access priority booking for our Manchester / Edinburgh / Birmingham pre-sales!</h4> */}
                <StyledTitle>
                  <h1 className="mb-2">THE MORMONS ARE COMING BACK!</h1>
                  {/* <h1 className="mb-2">
                                    <span>COMING TO</span>
                                    Edinburgh Playhouse
                                    <span>FROM</span>
                                    15 October - 2 November 2024
                                </h1> */}
                  {/* <h2 style={{color: 'black'}}>COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 - 25 JANUARY 2025
                                <br />
                                GENERAL ON SALE FROM MONDAY 1 MARCH</h2>                                 */}
                  {/* <h2 style={{color: 'black'}}>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h2>                                 */}
                  {/* <h2 style={{color: 'black'}}>Priority booking opens Monday 26 February.</h2>  */}
                  {/* <h2 style={{color: 'black'}}>COMING TO THEATRE ROYAL, PLYMOUTH FROM 14 – 25 JANUARY 2025</h2> */}
                  {/* <h3>SIGN UP BELOW TO ACCESS PRIORITY BOOKING</h3> */}
                  {/* <h3>SIGN UP FOR OUR EMAIL LIST</h3> */}
                  <h2 style={{ color: "black" }}>SIGN UP FOR OUR EMAIL LIST</h2>
                  {/* <p>Yes! I would like to recieve updates, special offers and other information from The Book of Mormon musical. </p> */}
                  {/* <h4 className="mb-5">SIGN UP TO OUR EMAIL LIST</h4> */}
                </StyledTitle>
              </>
            )}

            <NewSignUpForm title={false} {...props} city={city}  />
          </Content>
        </Container>
      </Container>
    </Layout>
  );
};

export default SignUpPage;
