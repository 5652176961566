import React, {Component} from 'react'
import {Link} from 'gatsby'
import {Collapse} from 'reactstrap'
import { navigate } from "gatsby"


/* eslint-disable */
class Header extends Component {

    constructor(props) {
        super(props)

        this.state = {
            menuCollapses: {
                collapse1: false,
                collapse2: false,
                collapse3: false
            },
            navToggled: false,
            navMobileToggled: false,
            city: ''
        }
    }

    toggleNav = () => {
        this.setState({
            navToggled: !this.state.navToggled,
            navMobileToggled: false
        })
    }

    toggleMobileNav = () => {
        this.setState({
            navMobileToggled: !this.state.navMobileToggled,
            navToggled: false
        })
    }

    setCity = (city) => {
        if (this.state.city === city)
        this.setState({
            city
        })
    }

    toggleMenuAccordion = (e) => {
        e.preventDefault()

        let menuCollapses = {...this.state.menuCollapses}
        const collapse = e.currentTarget.id

        for (var key in menuCollapses) {
            if (menuCollapses.hasOwnProperty(key) && collapse !== key) {
                menuCollapses[key] = false
            }
        }

        menuCollapses[collapse] = !menuCollapses[collapse]

        this.setState({menuCollapses})
    }

    render() {
        console.log(`state=${this.state.city}`)
        const {navToggled, navMobileToggled, city} = this.state
        const {siteId, phase, multiLang, language, displayHeader, path} = this.props.data
        const menuCollapses = {...this.state.menuCollapses}
        let url
        if (siteId === 'newcastleFanPerformance') {
            url = '/newcastle'
        } else {
            url = `/${siteId}`
        }

        if (siteId === '') {
            return true
        }

        if (multiLang) {
            url += `/${language}`;
        }

        if (!displayHeader)
            return false
        else
            return (
                <React.Fragment>
                    <header>
                        {phase === 'open' &&
                            <>
                                <div className="burger-menu">
                                    <a className={`open-nav ${navMobileToggled ? 'is-open' : ''}`} href="#"
                                       onClick={this.toggleMobileNav}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>
                                </div>
                            </>
                        }

                        <button className={`open-loc-nav ${phase !== 'open' && 'open'} ${navToggled && 'is-open'}`}
                                onClick={this.toggleNav}>
                            <span>Select Location</span>
                            <span>Close</span>
                        </button>

                        <nav className={`navigation ${navMobileToggled ? 'is-open' : ''}`}>
                            <ul className="navigation__primary-ul">
                                {/* {siteId === 'zurich' &&
                                    <li><Link className={`language-link ${language === 'de' ? 'active' : ''}`}
                                              to={`/${siteId}/de/`}>DE</Link> | <Link
                                        className={`language-link en ${language === 'en' ? 'active' : ''}`}
                                        to={`/${siteId}/en/`}>EN</Link></li>
                                } */}
                                {siteId === 'aarhus' &&
                                    <li><Link className={`language-link ${language === 'da' ? 'active' : ''}`}
                                              to={`/${siteId}/da/`}>DA</Link> | <Link
                                        className={`language-link en ${language === 'en' ? 'active' : ''}`}
                                        to={`/${siteId}/en/`}>EN</Link></li>
                                }
                                {phase === "open" && siteId !== 'uk-eu' &&
                                    <li><Link activeClassName="active" to={`${url}/`}>Home</Link>
                                    </li>
                                }
                                {phase === "open" && siteId !== 'uk-eu'  &&
                                    <li><Link activeClassName="active" to={`${url}/show/`}>The&nbsp;Show</Link></li>
                                }
                                {phase === "open" && siteId !== 'uk-eu' &&
                                    <li><Link activeClassName="active" to={`${url}/press/`}>Press</Link></li>
                                }
                                {phase === "open" && siteId !== 'uk-eu' &&
                                    <li><Link activeClassName="active" to={`${url}/gallery/`}>Gallery</Link></li>
                                }
                                {phase === "open" &&
                                    <li><a target="_blank" href="https://www.bookofmormonshop.co.uk/">Shop</a></li>
                                }
                                {phase === "open" &&
                                    <>
                                        {siteId === 'zurich' ?
                                            <li><a rel="noreferrer noopener" target="_blank"
                                                   href="https://twitter.com/bookofmormoneu">@Bookofmormoneu</a></li>
                                            :
                                            <li><a rel="noreferrer noopener" target="_blank"
                                                   href="https://twitter.com/bookofmormonuk">@Bookofmormonuk</a></li>
                                        }
                                    </>
                                }
                            </ul>
                        </nav>
                    </header>


                    <div className={`loc-navigation ${navToggled ? 'is-open' : ''}`}>

                        <p>SELECT LOCATION</p>

                        <ul className="navigation__ul">
                            {siteId !== 'london' &&
                                <li>
                                    <Link to="/london/">London</Link>
                                </li>
                            }
                            {siteId !== 'manchester' &&
                                <li>
                                    <Link to="/manchester/">Manchester</Link>
                                </li>
                            }
                            {siteId !== 'edinburgh' &&
                                <li>
                                    <Link to="/edinburgh/">Edinburgh</Link>
                                </li>
                            }
                                    {siteId !== 'bradford' &&
                                        <li>
                                            <Link to="/bradford/">Bradford</Link>
                                        </li>
                                    }
                            {siteId !== 'birmingham' &&
                                <li>
                                    <Link to="/birmingham/">Birmingham</Link>
                                </li>
                            }
                            {siteId !== 'plymouth' &&
                               <li>
                                    <Link to="/plymouth/">plymouth</Link>
                                </li>
                            }
                            {siteId !== 'hull' &&
                            <li>
                                       <Link to="/hull/">hull</Link>
                            </li>
                                  
                            }
                            {siteId !== 'dublin' &&
                            <li>
                                       <Link to="/dublin/">Dublin</Link>
                            </li>
                                  
                            }
                            {siteId !== 'zurich' &&
                            <li>
                                       <Link to="/zurich/">Zurich</Link>
                            </li>
                                  
                            }
                            <li><a href="https://thebookofmormontour.com/">US Tour</a></li>
                            {/* <li><Link to="/uk-eu/signup/">UK/European Tour</Link></li> */}
                            <li><a href="https://bookofmormonbroadway.com/">Broadway</a></li>
                            <li><a href="https://thebookofmormonmusical.com.au">Australia</a></li>
                            {/* <li><a href="https://bookofmormonmusical.com.au/ticketing/new-zealand/">New Zealand</a></li> */}
                        </ul>
                    </div>
                </React.Fragment>
            )
    }
}

export default Header
