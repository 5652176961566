import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import lottery from 'images/birminghamticketlottery.svg'

class Birmingham extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
            coronaMessageOpen: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    closeCoronaMessage = () => {
        this.setState({
            coronaMessageOpen: false
        })
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'birmingham') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        console.log(futureEvents)

        return (
            <Calendar
                events={futureEvents}
                defaultDate={moment('2024-12-1', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2024-12-1', 'YYYY-MM-DD'),
                    end: moment('2024-12-31', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2024-12-1', 'YYYY-MM-DD'),
                    end: moment('2024-12-31', 'YYYY-MM-DD')
                }}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <React.Fragment>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO BIRMINGHAM</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for BIRMINGHAM</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/* <li>Online ticket lottery <a href="https://thebookofmormonmusical.com/birminghamlottery/">NOW OPEN!</a></li> */}
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    {showCalendar &&
                                        <>
                                            <h2 className='py-3'>ONLINE BOOKINGS</h2>
                                            <ul className="keyList">
                                                <li>
                                                    <div className="keyList__key">KEY:</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__sold-out">SOLD OUT</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__limited">LIMITED</div>
                                                </li>
                                                <li>
                                                    <div className="keyList__available">AVAILABLE</div>
                                                </li>
                                            </ul>

                                            {this.loadEvents(this.props.events.allEventsJson.edges)}
                                        </>
                                    }
                                    <div className="section-accordion__wrap">
                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKINGS
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} sunderland`}>

                                            {showCalendar &&
                                            <>
                                                <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">KEY:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                </ul>

                                                {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            </>
                                            }
                                        </Collapse> */}

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseA}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseA === true ? 'active' : ''}`}>
                                                    <p>Group Bookings</p>
                                                    <p>0207 206 1174</p>
                                                    <p>(Mon &ndash; Fri, 9am &ndash; 5.30pm)</p>
                                                    <br />
                                                    <p>Box Office Opening Hours</p>
                                                    <p>2 hours prior to performance commencing. The Box Office will open every Wednesday afternoon between 12pm-5pm.</p>
                                            {/* <p>Ticket Sales <a href="tel:08443385000">0121 643 5536</a> * <br/>Mon – Sat 10am – 8pm (6pm on non-performance days)</p>
                                            <p>&nbsp;</p>
                                            <p>Groups Sales <a href="tel:0207 206 1174">0207 206 1174</a> * <br/>(Mon – Fri 9am - 5:30pm)</p>
                                            <p>&nbsp;</p>
                                            <p>ACCESS PERFOMANCES <a href="tel:0333 009 5399">0333 009 5399</a> * <br/>(Mon – Fri, 10am – 4pm)</p>
                                            <p>&nbsp;</p>
                                            <p>*Calls cost 4.5p per min. plus your phone company’s access charge</p>
                                            <p>&nbsp;</p> */}
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            VIP PACKAGES
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p><strong>THE AMBASSADOR LOUNGE</strong></p>
                                            <p>The Ambassador Lounge includes a special range of bespoke packages for you, giving you the opportunity to make your visit even more memorable.&nbsp;</p>
                                            <p>Please note, the Ambassador Lounge is only accessed via steps.</p>
                                            <p><a href="https://www.atgtickets.com/venues/the-alexandra-theatre-birmingham/your-visit/">Find out more</a>&nbsp;</p>
                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            ACCESS PERFORMANCES
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                            <p>ATG Access Line</p>
                                            <p>0333 009 5399</p>
                                            <p>(Mon &ndash; Fri, 10am &ndash; 4pm)&nbsp;</p>

                                                {/* <p><strong>BSL / Audio Described Performance</strong> – Sat 7 Mar, 2.30pm</p>
                                                <p><strong>Captioned Performance</strong> – Sat 14 Mar, 2.30pm</p>
                                                <p>&nbsp;</p> */}
                                            </div>
                                        </Collapse>

                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseE === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseE', e)}>
                                            ONLINE LOTTERY
                                        </button>
                                        <Collapse isOpen={collapses.collapseE}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseE === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                               <img src={lottery} alt="" title="" className="birmingham-lottery"/>
                                                <p>&nbsp;</p>
                                                <p>A limited number of tickets for each performance from 9 March are available at £15 per ticket via the online ticket lottery. Each winner drawn at random will be allowed to purchase one pair of seats.</p>
                                                <p>&nbsp;</p>
                                                <p>Entries accepted from 12 noon (GMT/BST) on Wednesday 4 March to 12 noon (GMT/BST) on Thursday 5 March for all performances. Terms apply</p>
                                                <p>&nbsp;</p>
                                                <a className="btn btn-orange-img full-width" href="https://thebookofmormonmusical.com/birminghamlottery/">
                                                   Click to enter
                                                </a>
                                            </div>
                                        </Collapse> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW</h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/birmingham/"
                                        locationName="birmingham"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/birmingham/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2>THE BOOK OF MORMON IS COMING TO BIRMINGHAM
                                    </h2>
                                    <br/>
                                    <h3 className="h2-presale">PUBLIC BOOKING</h3>
                                    <h3 className="highlight">OPENS 10:00AM<br/>
                                        TUESDAY 22 OCTOBER</h3>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map birmingham`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>3 - 28 December 2024</h3>
                                                <p>The Alexandra Theatre <br/>
                                                Suffolk Queensway, Birmingham, West Midlands, B5 4DS</p>

                                                <a className="btn btn-google-maps"
                                                   href="https://maps.app.goo.gl/q5cZu8KkCAzAmCD28"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">Birmingham Hippodrome Map</span>
                                                </a>

                                                <h3>PERFORMANCE TIMES</h3>
                                                <p>Monday – Saturday 7.30pm (excluding 24 & 25 Dec)</p>
                                                <p>Friday and Saturday 2.30pm</p>
                                                <p>Mon 23 & Tue 24 Dec 2.30pm</p>


                                                <h3 className="mt-2">Running Time</h3>
                                                <p>2 hours 20 minutes, including interval</p>
                                                <br />
                                                <p><strong>Parental advisory:</strong> Explicit language.</p>
<p><strong>Please note:</strong>&nbsp;This production contains strobe lighting, flashing lights and gunshots.&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }

                <div className={`coronaMsg ${!this.state.coronaMessageOpen && 'd-none'}`}>
                    <div className="coronaMsg--header">
                        <p>Coronavirus update</p>
                        <span className="coronaMsg--closeBtn" role="button" tabIndex={0} onKeyPress={this.closeCoronaMessage} onClick={this.closeCoronaMessage}>X</span>
                    </div>
                    <div className="coronaMsg--content">
                        <p>The health and safety of our cast, staff and audiences are our priority and we are, therefore, under today’s direction from the UK Government, suspending performances of The Book of Mormon in London and Birmingham with immediate effect and until further notice.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>We will continue to monitor the global situation and will keep our audiences updated regarding future performances, which we very much hope we are able to resume. Anyone with tickets for a suspended performance will be entitled to exchange into a future performance or a refund where applicable. Please contact your point of purchase to explore your options.</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Birmingham events={data}
                        multiLang={props.multiLang}
                        phase={props.phase}
                        siteId={props.siteId}
                        language={props.language}
            />
        )}
    />
)