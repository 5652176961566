import React, {Component} from 'react';
import {Container, Row, Col, Collapse} from 'reactstrap'
import SignUpForm from 'components/SignUpForm'
import {Link} from "gatsby";
import Calendar from 'components/Calendar'
import {StaticQuery, graphql} from "gatsby"
import moment from 'moment'
import star from "images/star.svg"

class Cardiff extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCalendar: true,
            collapses: {
                collapseA: true,
                collapseB: false,
                collapseC: false,
                collapseD: false,
            },
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({showCalendar: true})
        }, 1000)
    }

    loadEvents = (events) => {
        let futureEvents = [];

        for (var key in events) {
            if (events[key].node.siteId === 'cardiff') {
                for (var eventKey in events[key].node.items) {
                    if (moment().diff(events[key].node.items[eventKey].start, 'hours') <= 0 && events[key].node.items[eventKey].active) {
                        futureEvents.push(events[key].node.items[eventKey]);
                    }
                }
            }
        }

        return (
            <Calendar
                events={futureEvents}
                defaultDate={moment('2021-10-12', 'YYYY-MM-DD')}
                visibleRange={{
                    start: moment('2021-10-12', 'YYYY-MM-DD'),
                    end: moment('2021-11-01', 'YYYY-MM-DD')
                }}
                validRange={{
                    start: moment('2021-10-12', 'YYYY-MM-DD'),
                    end: moment('2021-11-01', 'YYYY-MM-DD')
                }}
            />
        )

    };

    toggleAccordion = (id, e) => {
        let collapses = {...this.state.collapses};
        const collapse = id;

        for (var key in collapses) {
            if (collapses.hasOwnProperty(key) && collapse !== key) {
                collapses[key] = false;
            }
        }

        collapses[collapse] = true;

        this.setState({collapses});
    };

    render() {
        const {phase} = this.props;
        const {showCalendar} = this.state;

        const collapses = {...this.state.collapses};

        return (
            <>
                <div className="section-intro">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {phase === 'registration' &&
                                <h1 className="text-center">THE MORMONS ARE COMING TO CARDIFF</h1>
                                }

                                {phase === 'open' &&
                                <h1>How to buy your tickets for CARDIFF</h1>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

                {phase === 'open' &&
                <>
                    <div className="section-intro section-sign-up">
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <div className="section-intro__wrapper">
                                        <div className="section-intro__wrapper-header">
                                            <h2>TICKET TIPS</h2>
                                        </div>
                                        <div className="section-intro__wrapper-body">
                                            <h3>WE KNOW IT CAN BE HARD SOMETIMES FINDING THE RIGHT TICKET SO HERE ARE A
                                                FEW HANDY TIPS:</h3>

                                            <ul>
                                                <li>SIGN-UP <a href="#footer">HERE</a> to receive latest news including
                                                    priority seat
                                                    releases
                                                </li>
                                                <li>For a wider range of prices and seats, try midweek performances</li>
                                                {/*<li>For access performances <a href="#section-accordion" onClick={(e) => this.toggleAccordion('collapseB', e)}>click here</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="section-accordion" id="section-accordion">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="section-accordion__wrap">
                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseA === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseA', e)}>
                                            ONLINE BOOKINGS
                                        </button>
                                        <Collapse
                                            isOpen={collapses.collapseA}
                                            onEntering={this.onEntering}
                                            onEntered={this.onEntered}
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            className={`section-accordion__wrap-content accordion-content has-inner a ${collapses.collapseA === true ? 'active' : ''} sunderland`}>

                                            {showCalendar &&
                                            <>
                                                <ul className="keyList">
                                                    <li>
                                                        <div className="keyList__key">KEY:</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__sold-out">SOLD OUT</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__limited">LIMITED</div>
                                                    </li>
                                                    <li>
                                                        <div className="keyList__available">AVAILABLE</div>
                                                    </li>
                                                </ul>

                                                {this.loadEvents(this.props.events.allEventsJson.edges)}
                                            </>

                                            }
                                        </Collapse>

                                        {/* <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseD === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseD', e)}>
                                            PHONE BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseD}
                                                  className={`section-accordion__wrap-content accordion-content d ${collapses.collapseD === true ? 'active' : ''}`}>
                                            <p><strong>BOOK BY PHONE</strong></p>

                                            <p>&nbsp;</p>
                                            <p><a href="tel:02920636464">029 2063 6464</a>*</p>
                                            <p>Mon – Sat: 10am – 6pm, Sun: Closed</p>
                                            <p>&nbsp;</p>
                                            <p>*Mae croeso i chi gysylltu â ni yn Gymraeg</p>

                                        </Collapse> */}

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseC === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseC', e)}>
                                            GROUP BOOKINGS
                                        </button>
                                        <Collapse isOpen={collapses.collapseC}
                                                  className={`section-accordion__wrap-content accordion-content has-inner c ${collapses.collapseC === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                                <p><strong>BOOK BY PHONE</strong></p>

                                                <p>&nbsp;</p>

                                                <p>Groups 10+  from £5 off, 20+  from £6 off , 40+  from £7 off</p>
                                                <p>Available Mon - Thu, top 2 prices</p>
                                                <p>&nbsp;</p>
                                                <p><a href="tel:02920636464">029 2063 6464</a>* (option 4)</p>
                                                <p><a href="https://www.wmc.org.uk/en/your-visit/group-bookings-and-travel-trade/organise-a-group-visit/" target="_blank" rel="noreferrer noopener">Organise a group visit</a></p>
                                                <p>&nbsp;</p>
                                                <p>*Mae croeso i chi gysylltu â ni yn Gymraeg</p>
                                            </div>
                                        </Collapse>

                                        <button
                                            className={`section-accordion__wrap-btn accordion-btn ${collapses.collapseB === true ? 'active' : ''}`}
                                            onClick={(e) => this.toggleAccordion('collapseB', e)}>
                                            ACCESS
                                        </button>
                                        <Collapse isOpen={collapses.collapseB}
                                                  className={`section-accordion__wrap-content accordion-content has-inner b ${collapses.collapseB === true ? 'active' : ''}`}>
                                            <div className="section-accordion__wrap-content-inner">
                                                <p>If you have access requirements that cannot be accommodated online, please call Wales Millennium Centre on 029 2063 6464* (option 3) to make your booking.</p>
                                                <p>&nbsp;</p>
                                                <p>Wheelchair positions are now bookable online, however if you are part of the access scheme Hynt and require a carer ticket, you will need to book over the phone.</p>
                                                <p>&nbsp;</p>
                                                <p>Find out more: <a href="https://www.wmc.org.uk/access" target="_blank" rel="noreferrer noopener">https://www.wmc.org.uk/access</a></p>
                                                <p>&nbsp;</p>
                                                <p>*Mae croeso i chi gysylltu â ni yn Gymraeg</p>
                                            </div>
                                        </Collapse>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                }

                {phase === 'registration' &&
                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h2 className={`h3`}>SIGN UP NOW AND GET ACCESS TO THE PRESALE <br/>REGISTER NOW</h2>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/cardiff/"
                                        locationName="cardiff"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/cardiff/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'presale' &&
                <div className="section-sign-up space-below">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap orange-border">
                                    <h3>WALES MILLENNIUM CENTRE<br/>
                                        <img src={star} className="star" alt="" title=""/> <span className="orange">DATE CHANGE</span> <img src={star} className="star" alt="" title=""/></h3>
                                    <div className="narrow">
                                        <p>&nbsp;</p>
                                        <p>Due to the ongoing Covid-19 restrictions, all performances at the Wales Millennium Centre (3 – 28 November 2020) have been rescheduled to</p>
                                        <p>&nbsp;</p>
                                        <p className="big orange text-uppercase"><strong>TUESDAY 12 – SUNDAY 31 OCTOBER 2021</strong></p>
                                        <p>&nbsp;</p>
                                        <p>Ticketholders for the 2020 run do not need to do anything. The theatre will contact you regarding the next steps.</p>
                                        <p>&nbsp;</p>
                                        <p>Non-ticket holders wishing to book for the 2021 run are advised to <br className="d-none d-md-inline-block"/><strong>sign up for priority booking</strong></p>

                                    </div>
                                    <SignUpForm
                                        className="sign-up-form"
                                        location="/cardiff/"
                                        locationName="cardiff"
                                        phase={phase}
                                        thankYouPage={true}
                                    />
                                    <p className="small-copy">
                                        By submitting your email address, you are agreeing to receive marketing
                                        emails about The Book of Mormon. You can opt out of receiving
                                        these emails at any time.
                                        <br/>To see full details of our privacy policy <Link
                                        to={`/cardiff/privacy-policy/`}>click here</Link>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
                {phase === 'open' &&
                <div className="section-show-info">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-show-info__wrap">
                                    <div className="row">
                                        <div className="section-show-info__wrap-map">
                                            <h2 className="d-lg-none">Show Info</h2>
                                            <div className={`map cardiff`}>
                                            </div>
                                        </div>

                                        <div className="section-show-info__wrap-content">
                                            <div className="content">
                                                <h2 className="d-none d-lg-block">Show Info</h2>
                                                <h3>FROM 12 OCTOBER 2021</h3>
                                                <p>Wales Millennium Centre,<br/>
                                                Bute Place, Cardiff Bay, CF10 5AL</p>

                                                <a className="btn btn-google-maps"
                                                   href="https://goo.gl/maps/C9KQ6yCHSesQ74827"
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                    <span className="sr-only">Wales Millennium Centre Map</span>
                                                </a>

                                                <h3>PERFORMANCE TIMES</h3>
                                                <p>Tue-Sat 7:30pm</p>
                                                <p>Fri, Sat & Sun 2:30pm</p>

                                                <h3 className="mt-2">Running Time</h3>
                                                <p>2 hours 20 minutes, including interval</p>
                                                <p>&nbsp;</p>
                                                <p>Parental Advisory:</p>
                                                <p>Age Guidance: 14+ (No Under 2s). Contains strong language, adult themes, strobe lighting and bangs.</p>
                                                <p>Under 16s must be accompanied by an adult.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                }
            </>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
        query {
            allEventsJson {
                edges {
                    node {
                        siteId
                        items {
                            url
                            start
                            title
                            className
                            active
                            type
                        }
                    }
                }
            }
        }`}
        render={data => (
            <Cardiff events={data}
                        multiLang={props.multiLang}
                        phase={props.phase}
                        siteId={props.siteId}
                        language={props.language}
            />
        )}
    />
)